type HookProps<T> = {
  data: T;
  id?: string;
};

export default function useContentSubmit<T>({ data, id }: HookProps<T>) {
  const obj = {};
  const keysThatAreDelete = [
    'image_file',
    'tooltip',
    'requester',
    'highlight,',
    'audio_file',
  ];
  const keyExceptions = ['file_id', 'file_name', 'file_toltip', 'file'];

  Object.assign(obj, data);
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'string' && obj[key].trim() === '') {
      delete obj[key];
    }
  });

  delete obj['highlight'];

  if (data['visibility'] === 'restricted' && data['groups']) {
    obj['groups'] = data['groups'].map(group => group.value || group);
  }

  if (data['visibility'] !== 'restricted' && data['groups']?.length) {
    delete obj['groups'];
  }
  if (!obj['image_file'] || obj['image_file'] === undefined) {
    obj['image_name'] = `Sebrae Minas - ${obj['title']}`;
    obj['image_toltip'] = `Sebrae Minas - ${obj['title']}`;
  }

  if (!obj['content_id']) obj['content_id'] = id;

  for (const key in obj) {
    if ((!obj[key] && !keyExceptions.includes(key)) || obj[key] === '') {
      delete obj[key];
    }
  }

  keysThatAreDelete.forEach(key => delete obj[key]);

  return { obj };
}
