import { AddCircle, Attachment, Delete, Save } from '@mui/icons-material';
import ArticleIcon from '@mui/icons-material/Article';
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Modal,
  Toolbar,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Controller, useForm } from 'react-hook-form';
import { Control, FieldValues, UseFormSetValue } from 'react-hook-form';
import Swal from 'sweetalert2';

// Helper
import toast from '../../../helpers/toast';
import UploadedFile from '../../../models/from-api-response/file';
import { getCategories } from '../../../services/categorization';
import { createEpisode, IEpisode } from '../../../services/episodes';
import { searchFile, uploadFile } from '../../../services/files';
import CategoryDropDown from '../../CategoryDropDown';
import Container from '../../Drag/Container';
import * as styles from './styles';

// Interfaces
interface IEpisodesProps {
  setValue: UseFormSetValue<FieldValues>;
  content: any;
  view: boolean;
  control?: Control<FieldValues>;
}

const Input = styled('input')({
  display: 'none',
});

export default function Episodes(props: IEpisodesProps) {
  const {
    setValue: setValueProps,
    content,
    view,
    // control: controlProps,
  } = props;

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [files, setFiles] = useState<FileList>();
  const [fileName, setFileName] = useState<string>('');
  const [episodes, setEpisodes] = useState<any>([]);
  const [fileToolTip, setFileToolTip] = useState<string>('');
  const [modalPreview, setModalPreview] = useState<string | ArrayBuffer>();
  const [isEditing, setIsEditing] = useState(false);
  const [category, setCategory] = useState<any[]>([]);
  const [skill, setSkill] = useState<any>();
  const [backCategories, setBackCategories] = useState<string[]>([]);
  const [episodeName, setEpisodeName] = useState<string>('');
  const [episodeDescription, setEpisodeDescription] = useState<string>('');

  const ordersToRefPodcast: any = useRef();
  ordersToRefPodcast.current = episodes;

  const refSkill: any = useRef();
  refSkill.current = skill;

  useEffect(() => {
    getCategories().then((data: any) => {
      setSkill({ ...props, category: data.skills, setState: setCategory });
    });
  }, []);

  useEffect(() => {
    if (content) {
      fillFile();
      if (content.file_id) {
        getFile(content.file_id);
      }
    }
  }, []);

  useEffect(() => {
    const complementFilesId: any[] = [];
    episodes.forEach((files: any) => {
      complementFilesId.push(files.episode_id);
    });
    setValueProps('episodes', complementFilesId);
  }, [episodes]);

  const { setValue, control, handleSubmit } = useForm();

  const fillFile = () => {
    const editing = content;
    if (editing) {
      setFileName(editing.name);
      setValueProps('episodes', editing.episodes);
      setEpisodes(editing.episodes);
    }
  };

  const getFile = async (formData: any) => {
    searchFile(formData).then(data => {
      setFileName(data.original_name);
      setValueProps('file_id', data.file_id);
    });
  };

  const deleteMain = async () => {
    setValueProps('complement_files', []);
    setEpisodes([]);
    setFiles(undefined);
    setFileName('');
  };
  function sendEpisode(data: UploadedFile): void {
    const episodeElement = document.createElement('audio');
    episodeElement.src = data.reference;

    episodeElement.addEventListener('loadedmetadata', () => {
      const duration = parseInt(episodeElement.duration.toString()) * 1000;

      const episode: IEpisode = {
        file_id: data.file_id,
        name: episodeName,
        description: episodeDescription,
        duration: duration,
      };
      createEpisode(episode)
        .then(episodeResponse => {
          setEpisodes([...episodes, episodeResponse]);
          episodeElement.remove();
          toast({
            message: 'Episódio criado com sucesso',
            type: 'success',
          });
          setModalOpen(false);
          Swal.fire({
            title: 'Mídia criada com sucesso',
            icon: 'success',
          });
        })
        .catch(err => {
          toast({
            message: err.data.message,
            type: 'warning',
          });
        });
    });
  }

  function onSubmit(data: any) {
    if (!isEditing) {
      const formData = new FormData();
      if (files !== undefined) {
        if (category.length > 1) {
          const file = files[0];
          const blob = file.slice(0, file.size, file.type);
          const fileSplited = files[0].name.split('.');
          const newFile = new File(
            [blob],
            fileName + '.' + fileSplited[fileSplited.length - 1],
            { type: file.type },
          );

          formData.append('file', newFile);
          formData.append('tooltip', fileToolTip);
          let categoryCopy: any[] = [];
          categoryCopy = category;
          categoryCopy.forEach(el =>
            formData.append('categories[]', el.category_id),
          );
          uploadFile(formData)
            .then(data => {
              sendEpisode(data);
            })
            .catch(err => {
              toast({
                message: err.data.message,
                type: 'warning',
              });
            });
          setModalOpen(false);
        } else {
          toast({
            message: 'É necessario escolher apenas uma categoria filha.',
            type: 'warning',
          });
        }
      }
    }
  }

  // const handleDeleteItem = (item: any) => () => {
  //   console.log(item);
  //   // const updatedFileComplementary = ordersToRefPodcast.current.filter(
  //   //   e => e.file_id !== item.file_id,
  //   // );
  //   // setEpisodes(updatedFileComplementary);
  // };

  const cleanModalState = async () => {
    setFileToolTip('');
    setFileName('');
    setValue('tooltip', null);
    setValue('file_name', null);
    setSkill({ ...refSkill.current, content: undefined });
    setModalPreview(undefined);
    setFiles(undefined);
  };

  function getModalStyle() {
    const top = 25;

    return {
      top: `${top}%`,
      margin: 'auto',
      width: '85%',
      height: '95%',
      backgroundColor: 'white',
      marginTop: '1vw',
      overflow: 'hidden',
      overflowY: 'scroll',
    } as React.CSSProperties;
  }

  const removeItem = (item: any) => {
    const updatedFileComplementary = ordersToRefPodcast.current.filter(
      e => e.file_id !== item.file_id,
    );
    setEpisodes(updatedFileComplementary);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem',
        width: '100%',
      }}
    >
      <Typography variant="h4" color={'black'}>
        {'Episódios'}
      </Typography>
      {
        <DndProvider backend={HTML5Backend}>
          <Container
            item={episodes}
            setItem={setEpisodes}
            onChangeDeleteItem={removeItem}
          />
        </DndProvider>
      }

      {episodes.length === 0 ? (
        <span
          style={{ color: 'disable', marginTop: '1rem', marginBottom: '1rem' }}
        >
          Nenhum arquivo selecionado
        </span>
      ) : (
        <></>
      )}

      <ButtonGroup
        style={{ boxShadow: '0px 0px' }}
        variant="contained"
        size="small"
      >
        <Button
          disabled={view}
          startIcon={<AddCircle />}
          onClick={() => {
            cleanModalState();
            setModalOpen(true);
            setIsEditing(false);
          }}
          style={{
            backgroundColor: '#008d4c',
            marginRight: 1,
          }}
        >
          <span>Adicionar novo</span>
        </Button>
        <Button
          disabled={view}
          onClick={() => {
            deleteMain();
          }}
          startIcon={<Delete />}
          style={{
            backgroundColor: '#d73925',
            marginRight: 1,
            display: 'flex',
          }}
        >
          <span>Remover Todos</span>
        </Button>
      </ButtonGroup>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ alignItems: 'center', justifyContent: 'center' }}
      >
        <Box style={getModalStyle()}>
          <form>
            <AppBar
              position="static"
              style={{ marginBottom: '1rem' }}
              sx={{ bgcolor: '#008d4c', height: '3.5rem' }}
            >
              <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  Mídia
                </Typography>
                <Button onClick={() => setModalOpen(false)} color="inherit">
                  x
                </Button>
              </Toolbar>
            </AppBar>

            <styles.Label>
              <p>Arquivo/Referência *</p>
            </styles.Label>
            {modalPreview !== undefined ? (
              <styles.ContentImg>
                <styles.Preview
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  src={modalPreview}
                />
              </styles.ContentImg>
            ) : (
              <styles.ContentImg>
                <ArticleIcon
                  color="disabled"
                  fontSize="large"
                  sx={{ height: '6em' }}
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '6em',
                  }}
                />
              </styles.ContentImg>
            )}
            <styles.ContentSeletor>
              <styles.Label>Nome Arquivo</styles.Label>
              <Box
                component="div"
                sx={{
                  '& .MuiTextField-root': { m: 0, width: '100%' },
                }}
              >
                <Controller
                  name="file_name"
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <>
                        <styles.InputText
                          disabled={isEditing}
                          name="file_name"
                          value={value}
                          onChange={event => {
                            onChange(event.target.value);
                            setFileName(event.target.value);
                          }}
                          required
                          id="outlined-required"
                        />
                        {error && (
                          <styles.spanRequired
                            style={{ color: 'red', marginBottom: '0.5rem' }}
                          >
                            *Campo obrigatório
                          </styles.spanRequired>
                        )}
                      </>
                    );
                  }}
                />
              </Box>
            </styles.ContentSeletor>
            <styles.ContentSeletor>
              <styles.Label>Tooltip Arquivo</styles.Label>
              <Box
                component="div"
                sx={{
                  '& .MuiTextField-root': { m: 0, width: '100%' },
                }}
              >
                <Controller
                  name="tooltip"
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <styles.InputText
                        name="tooltip"
                        value={value}
                        onChange={event => {
                          onChange(event.target.value);
                          setFileToolTip(event.target.value);
                        }}
                      />
                      {error && (
                        <styles.spanRequired
                          style={{ color: 'red', marginBottom: '0.5rem' }}
                        >
                          *Campo obrigatório
                        </styles.spanRequired>
                      )}
                    </>
                  )}
                />
              </Box>
            </styles.ContentSeletor>
            <styles.ContentSeletor>
              <styles.Label>Nome Episodio</styles.Label>
              <Box
                component="div"
                sx={{
                  '& .MuiTextField-root': { m: 0, width: '100%' },
                }}
              >
                <styles.InputText
                  name="name"
                  value={episodeName}
                  onChange={event => {
                    if (event?.target?.value?.length <= 70) {
                      setEpisodeName(event.target.value);
                    } else {
                      toast({
                        message:
                          'O tamanho do texto deve ser inferior a 70 caracteres.',
                        type: 'warning',
                      });
                    }
                  }}
                />
              </Box>
            </styles.ContentSeletor>
            <styles.ContentSeletor>
              <styles.Label>Descrição Episodio</styles.Label>
              <Box
                component="div"
                sx={{
                  '& .MuiTextField-root': { m: 0, width: '100%' },
                }}
              >
                <styles.InputTextArea
                  name="description"
                  value={episodeDescription}
                  onChange={event => {
                    if (event?.target?.value?.length <= 160) {
                      setEpisodeDescription(event.target.value);
                    } else {
                      toast({
                        message:
                          'O tamanho do texto deve ser inferior a 160 caracteres.',
                        type: 'warning',
                      });
                    }
                  }}
                  maxRows={4}
                  multiline
                />
              </Box>
            </styles.ContentSeletor>
            {files && (
              <CategoryDropDown
                setBackCategories={setBackCategories}
                backCategories={backCategories}
                formProps={skill}
              />
            )}
            <Box textAlign="center">
              <ButtonGroup
                variant="contained"
                size="small"
                style={{ margin: 'auto', marginBottom: '1rem' }}
              >
                <label htmlFor="contained-button-file">
                  <Input
                    accept="audio/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(event: any) => {
                      if (
                        event.target.files[0].type ===
                          'application/x-msdownload' ||
                        event.target.files[0].type ===
                          'application/x-msdos-program' ||
                        event.target.files[0].type ===
                          'application/octet-stream'
                      ) {
                        toast({
                          message:
                            'O tipo de arquivo não pode ser selecionado.',
                          type: 'warning',
                        });
                      } else {
                        setFiles(event.target.files);
                        const reader = new FileReader();
                        reader.readAsDataURL(event.target.files[0]);
                        reader.onloadend = (e: any) => {
                          setModalPreview(
                            reader.result != undefined ? reader.result : 'null',
                          );
                        };
                      }
                    }}
                  />
                  <Button
                    disabled={isEditing}
                    variant="contained"
                    size="small"
                    component="span"
                    startIcon={<Attachment />}
                    style={{
                      backgroundColor: '#00acd6',
                    }}
                  >
                    <span>Escolher arquivo</span>
                  </Button>
                </label>
                <Button
                  startIcon={<Save />}
                  disabled={!files || category.length === 0}
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: '#008d4c',
                    marginLeft: 10,
                  }}
                >
                  <span>Salvar</span>
                </Button>
              </ButtonGroup>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
